import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
})
export class ShareComponent implements OnInit {
  constructor() {
  }

  public static openModal() {
    $('.modal--share').show(300, () => {
      $('.modal--share').modal('show');
    });
  }

  ngOnInit() {
  }
}
