import {AfterViewInit, Component, EventEmitter, OnInit, ViewEncapsulation} from '@angular/core';
import {BrandService} from './services/brand.service';
import {StockApiService} from './services/stock-api.service';
import {ObservedOfferService} from './services/observed-offer.service';
import {ComparedOfferService} from './services/compared-offer.service';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Brand, Brands} from './models/Brands';
import {QueryStringService} from './services/query-string.service';
import {LocalStorageService} from './services/local-storage.service';
import {ConfigModel} from './config/config';
import {EmptyModalComponent} from './modals/empty-modal/empty-modal.component';
import {LiveChatService} from './services/live-chat.service';
import {ErrorHandler} from './tools/error-handler';
import {LabelProviderService} from './services/labelProvider.service';
import {TrackingService} from './services/tracking.service';
import {SessionStorageService} from './services/session-storage.service';

// declare ga as a function to set and sent the events
declare let ga: Function;
declare let sitedata: any;
declare let window: any;

@Component({
  providers: [
    BrandService
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
  static isDealerStock = false;
  static dealerCode: string = null;
  static changeStockDealer: EventEmitter<string> = new EventEmitter();
  static bodyClass = 'home';
  static goToHomepage: EventEmitter<any> = new EventEmitter();
  static THROW_404_ERROR = 'Throw 404 error';
  static offerPriceFinancialProductNotes = [];

  brand: Brand;
  isBrandLoaded = false;
  footerLinkToBrand = '';
  brandConfig: ConfigModel = null;
  debuggerEnabled = false;
  trackingDebuggerEmitter = new EventEmitter();
  show404Error = false;
  public classReference = AppComponent;
  currentModel = '0';
  QSSmodels: any;
  showNavListMenu: false;
  lfaUrl = null;
  bannerCounter = {
    enable: false,
    text: '',
    numberOfOffers: 0,
    totalOffersCount: 0
  };
  promoAR_oc_1 = false;
  dateInNote = '';

  constructor(
    private brandService: BrandService,
    private stockApiService: StockApiService,
    private router: Router,
    public observedOffers: ObservedOfferService,
    public comparedOffers: ComparedOfferService,
    private QSS: QueryStringService,
    private LSS: LocalStorageService,
    private liveChatService: LiveChatService,
    private labelProviderService: LabelProviderService,
    private SSS: SessionStorageService
  ) {
    let code = null;
    if (/\/dealer\-site\/\d{7}/.test(location.href)) {
      code = location.href.match(/\/dealer\-site\/(\d{7})/)[1];
    }
    this.getBrands(code).then(() => {
      if (environment.version === 'dev') {
        if (typeof BrandService.getCurrentBrand().slug !== 'undefined') {
          $('head').append('<link rel="stylesheet" href="/assets/css/style-' +
            BrandService.getCurrentBrand().slug +
            '.css" type="text/css"/>');
        } else {
          $('head').append('<link rel="stylesheet" href="/assets/css/style-fiat.css" type="text/css"/>');
        }
      }
      const ua = navigator.userAgent;
      const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
      if (is_ie) {
        $('head').append('<link rel="stylesheet" href="/assets/css/ie-style.css" type="text/css"/>');
      }

      this.isBrandLoaded = true;
      this.afterBrandLoad();

      if (this.QSS.getParam('calculator-test') !== null) {
        this.SSS.set('calculator-test', true);
      }

      const today = new Date(),
        dayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1),
        lastDayOfMonth = dayOfMonth.toISOString().split('T')[0].split('-');
      this.dateInNote = lastDayOfMonth[2] + '.' + lastDayOfMonth[1] + '.2024';
    }).catch(reason => {
      if (reason.message && reason.message !== AppComponent.THROW_404_ERROR) {
        console.log(reason);
        ErrorHandler.handle(reason);
      }
    });
  }

  static clearOfferPriceFinancialProductNotes() {
    this.offerPriceFinancialProductNotes = [];
  }
  static addOfferPriceFinancialProductNotes(note: string) {
    if (this.offerPriceFinancialProductNotes.indexOf(note) === -1) {
      this.offerPriceFinancialProductNotes.push(note);
    }
  }

  public static getCDNBaseUrl(): string {
    if (sitedata !== undefined && sitedata.cdn_base_url !== undefined) {
      return sitedata.cdn_base_url;
    }
    return environment.CDNBaseURL;
  }

  async getBrands(code): Promise<void> {
    if (sitedata !== undefined && sitedata.brands) {
      BrandService.setData(sitedata.brands);
      if (sitedata.show404Error && sitedata.show404Error === true) {
        this.show404Error = true;
      } else {
        this.labelProviderService.init();
      }
    } else {
      const brands = await this.stockApiService.getBrands(code);
      BrandService.setData(brands);
      this.labelProviderService.init();
    }
  }

  afterBrandLoad() {
    this.brandConfig = BrandService.getConfig();

    if (environment.version !== 'dev') {
      this.loadGoogleAnalytics();
    }

    if (BrandService.getConfig()) {
      this.brand = BrandService.getCurrentBrand();

      this.footerLinkToBrand = this.brandConfig.homeSite;

      const host = location.protocol + '//' + location.host;
      this.changeRoute($(location).attr('href').slice(host.length));
      this.router.events.subscribe((value: NavigationEnd) => {
        if (value instanceof NavigationEnd) {
          if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS) && typeof ga !== 'undefined') {
            ga('set', 'page', value.urlAfterRedirects);
            ga('send', 'pageview');
          }
          // window.__fs_dncs_instance.trackPage();
          this.changeRoute(value.urlAfterRedirects);
        }
      });
    }

    if (this.brand.slug === 'fiat' || this.brand.slug === 'fiat-professional') {
      if (environment.version === 'dev') {
        this.lfaUrl = {
          configurator: 'https://www.fiatprofessional.com/pl/konfigurator',
          testDrive: 'https://www.fiatprofessional.com/pl/jazda-probna',
          dealers: 'https://www.fiatprofessional.com/pl/znajdz-dealera'
        };
      }
      if (BrandService.getCurrentBrand().config !== undefined && BrandService.getCurrentBrand().config.lfaUrl !== undefined) {
        this.lfaUrl = BrandService.getCurrentBrand().config.lfaUrl;
      }
    }
  }

  private loadGoogleAnalytics(number = 0) {
    if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS)) {
      if (typeof ga !== 'undefined') {
        ga('create', environment.production ? this.brandConfig.googleAnalyticsKey : false, 'auto');
        ga('set', 'page', document.location.pathname);
        ga('send', 'pageview');
      } else {
        if (number <= 10) {
          setTimeout(() => {
            this.loadGoogleAnalytics(number + 1);
          }, 500);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.QSS.getParam('enable_stock_debugger') !== null) {
      this.LSS.setMultiBrand('stockdebug', true);
      this.debuggerEnabled = true;
    } else {
      if (this.LSS.getMultiBrand('stockdebug')) {
        this.debuggerEnabled = true;
      }
    }
  }

  private changeRoute(url: string) {
    AppComponent.clearOfferPriceFinancialProductNotes();
    const queryParamsCharPos = url.indexOf('?');
    let route: string;
    if (queryParamsCharPos > -1) {
      route = url.substr(0, queryParamsCharPos);
    } else {
      route = url;
    }

    if (/\/dealer\-site\/\d{7}/.test(route)) {
      const code = route.match(/\/dealer\-site\/(\d{7})/)[1];
      if (code !== AppComponent.dealerCode) {
        AppComponent.isDealerStock = true;
        AppComponent.changeStockDealer.emit(code);
        AppComponent.dealerCode = code;
        // this.stockApiService.getBrands(code).then(value => {
        //   this.brandService.setNewDataWithoutBrandChanging(value);
        // }).catch(reason => {
        //   document.location.href = '/page-not-found'
        //   ErrorHandler.handle(reason);
        // });
      }
    } else {
      if (AppComponent.dealerCode !== null) {
        AppComponent.isDealerStock = false;
        AppComponent.changeStockDealer.emit(null);
        AppComponent.dealerCode = null;
        // this.stockApiService.getBrands().then(value => {
        //   this.brandService.setNewDataWithoutBrandChanging(value);
        // }).catch(reason => {
        //   ErrorHandler.handle(reason);
        // });
      }
    }
    if (sitedata.sol_promo_offers_count !== undefined && this.brand.slug === 'alfa-romeo') {
      if (sitedata.sol_promo_offers_count > 0) {
        this.promoAR_oc_1 = true;
        this.bannerCounter.totalOffersCount = sitedata.sol_promo_total_offers_count;
      } else {
        this.promoAR_oc_1 = false;
      }
    } else {
      this.promoAR_oc_1 = false;
    }
    if ((this.generateNumberRandomForBanner(4) === 1 || this.QSS.getParam('showBannerWithCounter') === '1') &&
      sitedata.sol_promo_offers_count !== undefined && this.brand.slug === 'alfa-romeo') {

      this.bannerCounter.numberOfOffers = sitedata.sol_promo_offers_count;

      if (this.bannerCounter.numberOfOffers > 0) {
        let tmpHTML = '';
        this.bannerCounter.numberOfOffers.toString().split('').forEach((val) => {
          tmpHTML += '<div class="banner-counter-number">' + val + '</div>';
        });

        if (this.bannerCounter.numberOfOffers === 1) {
          this.bannerCounter.text = 'Tylko teraz ubezpieczenie OC/AC za 1% wartości samochodu przy zamówieniu on-line.<br />Ostatnia '
            + tmpHTML + ' sztuka w ofercie!';
        } else if (this.bannerCounter.numberOfOffers > 1 && this.bannerCounter.numberOfOffers < 5 ) {
          this.bannerCounter.text = 'Tylko teraz ubezpieczenie OC/AC za 1% wartości samochodu przy zamówieniu on-line.<br />Ostatnie '
            + tmpHTML + ' sztuki w ofercie!';
        } else {
          this.bannerCounter.text = 'Tylko teraz ubezpieczenie OC/AC za 1% wartości samochodu przy zamówieniu on-line.<br />Ostatnie '
            + tmpHTML + ' sztuk w ofercie!';
        }
        setTimeout(() => {
          this.bannerCounter.enable = true;
        }, 500);
      } else {
        this.bannerCounter.enable = false;
      }
    }
  }

  openPrivacyNotes() {
    $('body').addClass('cursor-progress');
    this.stockApiService.getPrivacyNotes().then(value => {
      EmptyModalComponent.openModal(value, true);
      $('body').removeClass('cursor-progress');
    }, reason => {
      $('body').removeClass('cursor-progress');
      ErrorHandler.handle(reason);
    });
  }

  generateNumberRandomForBanner(numberOfBanners) {
    return Math.floor(Math.random() * numberOfBanners);
  }

  clickLogo() {
    if (this.show404Error) {
      document.location.href = BrandService.getCurrentBrand().stockUrl;
    }
    AppComponent.goToHomepage.emit();
  }

  openCookieBar() {
    $('.cookie-info').trigger('click');
  }

  getLiveChatLicense() {
    return this.liveChatService.livechatLicense;
  }

  getDealerLiveChatGroup(dealerCode) {
    const group = this.liveChatService.getDealerLiveChatGroup(dealerCode);

    if (group !== false) {
      $('#livechat-compact-container').show(0);
      $('#livechat-eye-catcher').show(0);
    } else {
      this.addDealerTawkTo(dealerCode);
    }

    return group;
  }

  addDealerTawkTo(dealerCode) {
    const code = this.liveChatService.getDealerTawkToCode(dealerCode);

    if (code !== false && $('[title="tawk-to"]').length === 0) {
      $('head').append('<script title="tawk-to" src="https://embed.tawk.to/' + code + '/default" charset="UTF-8" async></script>');
    }
  }

  onActivate(elementRef) {
      if (typeof elementRef.currentModel !== 'undefined') {
        elementRef.currentModel.subscribe(event => {
          this.currentModel = event;
        });
      } else {
        this.currentModel = '0';
      }
  }

  checkQSS() {
    this.QSSmodels = this.QSS.getParam('models');
    return 1;
  }

  closeDebugger() {
    this.LSS.removeMultiBrand('stockdebug');
    this.debuggerEnabled = false;
  }

  openOrCloseNavListMenu(value) {
    this.showNavListMenu = value;
  }

  sendFloodlight(type) {
    if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.GTM) &&
      TrackingService.isOpenclPerformanceCookieEnabled()) {
      switch (BrandService.getCurrentBrand().code) {
        case '00':
          switch (type) {
            case 'configurator':
              this.sendGtm('10176142', 'fia_butt', 'fi_but_k');
              break;
            case 'testDrive':
              this.sendGtm('10176142', 'fia_butt', 'fi_but_j');
              break;
            case 'dealers':
              this.sendGtm('10176142', 'fia_butt', 'fi_but_z');
              break;
          }
          break;
        case '77':
          switch (type) {
            case 'configurator':
              this.sendGtm('10176142', 'fp_butto', 'fp_but_k');
              break;
            case 'testDrive':
              this.sendGtm('10176142', 'fp_butto', 'fp_but_j');
              break;
            case 'dealers':
              this.sendGtm('10176142', 'fp_butto', 'fp_but_z');
              break;
          }
          break;
        case '83':
          switch (type) {
            case 'configurator':
              this.sendGtm('10176142', 'al_butto', 'al_but_k');
              break;
            case 'testDrive':
              this.sendGtm('10176142', 'al_butto', 'al_but_j');
              break;
            case 'dealers':
              this.sendGtm('10176142', 'al_butto', 'al_but_z');
              break;
          }
          break;
        case '57':
          switch (type) {
            case 'configurator':
              this.sendGtm('10176142', 'je_butto', 'je_but_k');
              break;
            case 'testDrive':
              this.sendGtm('10176142', 'je_butto', 'je_but_j');
              break;
            case 'dealers':
              this.sendGtm('10176142', 'je_butto', 'je_but_z');
              break;
          }
          break;
        case '66':
          switch (type) {
            case 'configurator':
              this.sendGtm('10176142', 'ab_but_k', 'ab_but_k');
              break;
            case 'testDrive':
              this.sendGtm('10176142', 'ab_but_k', 'ab_but_j');
              break;
            case 'dealers':
              this.sendGtm('10176142', 'ab_but_k', 'ab_but_z');
              break;
          }
          break;
      }
    }
  }

  sendGtm(activityID, type, cat) {
    if ($('.iframe-Floodlight-' + cat).length) {
      $('.iframe-Floodlight-' + cat).remove();
    }

    const axel: any  = Math.random() + '';
    const a = axel * 10000000000000;
    const newIframe = document.createElement('iframe');
    newIframe.height = '1';
    newIframe.width = '1';
    newIframe.style.display = 'none';
    newIframe.className = 'iframe-Floodlight-' + cat;

    newIframe.src = 'https://' + activityID + '.fls.doubleclick.net/activityi;src=' + activityID + ';type=' + type + ';cat=' + cat +
      ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?;';
    document.body.appendChild(newIframe);
  }

  scrollToFaq = () => {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.sol-accordion-wrap').offset().top - 100
    }, 1000);
  }
}
