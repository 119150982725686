import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {ModalsModule} from './modals/modals.module';
import {StorageServiceModule} from 'angular-webstorage-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig} from '@angular/material';
import { ObservedOffersComponent } from './subpages/observed-offers/observed-offers.component';
import { ComparedOffersComponent } from './subpages/compared-offers/compared-offers.component';
import { CartComponent } from './subpages/cart/cart.component';
import { DealerListComponent } from './subpages/dealer-list/dealer-list.component';
import { TermsOfServiceComponent } from './subpages/terms-of-service/terms-of-service.component';
import { TermsOfPlatformComponent } from './subpages/terms-of-platform/terms-of-platform.component';
import {AppComponentsModule} from './app-components/app-components.module';
import {NgxMaskModule} from 'ngx-mask';
import {ToolsModule} from './tools/tools.module';
import {QueryStringService} from './services/query-string.service';
import {AdobeService} from './services/adobe.service';
import {LivechatWidgetModule} from '@livechat/angular-widget';
import * as Raven from 'raven-js';
import {environment} from '../environments/environment';
import {TitleService} from './services/title.service';
import {LazyLoadImageModule, scrollPreset} from 'ng-lazyload-image';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {OfferModule} from './modules/offer/offer.module';
import {SessionStorageService} from './services/session-storage.service';

Raven
  .config('https://ac47ddbbf07943d6aab7447f1ad22178@sentry.unicorn.net.pl/8')
  .setEnvironment(environment.production ? 'production' : (environment.version === 'dev' ? 'development' : 'staging'))
  .setRelease(environment.version)
  .install();

export class RavenErrorHandler extends ErrorHandler {
  handleError(err: any): void {
    const stringifyError = JSON.stringify(err);
    if (/exactag.js/.test(stringifyError)
       || /ebOneTag.js/.test(stringifyError)
       || /Object doesn't support this action/.test(stringifyError)
       || /Error: Loading chunk/.test(stringifyError)
       || /In this configuration Angular requires Zone.js/.test(stringifyError)
       || /Unexpected token </.test(stringifyError)
       || /G._promiseRejectionHandler/.test(stringifyError)
       || /'google' is not defined/.test(stringifyError)
    ) {
      console.warn(err);
    } else {
      if (environment.production) {
        Raven.captureException(err.originalError || err);
      } else {
        console.error(err);
      }
    }
  }
}
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ObservedOffersComponent,
    ComparedOffersComponent,
    CartComponent,
    DealerListComponent,
    TermsOfServiceComponent,
    TermsOfPlatformComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    ModalsModule,
    StorageServiceModule,
    AppComponentsModule,
    NgxMaskModule.forRoot({}),
    ToolsModule,
    LivechatWidgetModule,
    LazyLoadImageModule.forRoot({
      preset: scrollPreset
    }),
    NgxJsonViewerModule,
    OfferModule,
  ],
  providers: [
    // {provide: ErrorHandler, useClass: RavenErrorHandler},
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    QueryStringService,
    AdobeService,
    TitleService,
    SessionStorageService
  ],
  exports: [
    PageNotFoundComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
