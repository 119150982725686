<div class="modal modal--share fade" tabindex="-1" role="dialog" aria-labelledby="shareLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h3 class="modal-title text-uppercase" id="shareLabel">Udostępnij</h3>
        <span class="close" data-dismiss="modal" aria-label="Zamknij">
            <span class="icon-x"></span>
        </span>
      </div>
      <div class="modal-body">

        <share-button [theme]="'material-dark'" [button]="'facebook'"></share-button>

        <share-button [theme]="'material-dark'" [button]="'twitter'"></share-button>

        <share-button [theme]="'material-dark'" [button]="'pinterest'"></share-button>

        <share-button [theme]="'material-dark'" [button]="'messenger'"></share-button>

        <share-button [theme]="'material-dark'" [button]="'whatsapp'"></share-button>

        <share-button [theme]="'material-dark'" [button]="'copy'"></share-button>

      </div>
    </div>
  </div>
</div>
